import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'
import { SRLWrapper } from "simple-react-lightbox"

function BlogPage() {
  const options = {
    settings: {
      overlayColor: "rgba(255, 255, 255, 0.7)",
      autoplaySpeed: 3000,
      transitionSpeed: 3000,
    },
    thumbnails: {
      showThumbnails: false
    },
    buttons: {
      backgroundColor: "rgb(77,77,77)",
      iconColor: "grey",
    },
    caption: {
      captionColor: "black",
      captionFontFamily: "Raleway, sans-serif",
      captionFontWeight: "300",
    }
  };

  const data = useStaticQuery(graphql`
    query {
      allContentfulPainting (
          sort: {fields: [actualDate], order: DESC}
        ) {
        edges {
          node {
            title
            technique
            height
            width
            date
            image {
              file {
                url
              }
            }

            thumbnail {
              file {
                url
              }
            }


          }
        }
      }
    }
  `)

  let date = 0;
  return (
    <Layout>
      <Head title="Paintings"/>
      <SRLWrapper options={options}>
        <ol class="main">
          <div class="container">
            {/* <img src={data.allContentfulPainting.edges[0].node.thumbnail.file.url}></img> */}
            {data.allContentfulPainting.edges.map((edge) =>{
              return (
                <div>
                  <div class="card">                  
                    <div class="card-image">
                      <a
                        href={edge.node.image.file.url}
                        data-attribute="SRL"
                        className="pseudo-element"
                      >
                      <img
                        class="square-img"
                        src={edge.node.thumbnail.file.url}
                        alt={edge.node.title + " | " + edge.node.technique + " | " + edge.node.height + " x " + edge.node.width + " cm | " + edge.node.date}
                      />
                      </a>
                    </div>
                  </div>
                </div>              )
            })}
          </div>
      </ol>

    </SRLWrapper>

    </Layout>
    )
}

export default BlogPage